import { DEBUG_LEVEL, LOG_LEVEL } from "./constants";
import {getLogLevel, setDebugEnabled, setLogLevel} from "./cookieManager";
import { getStyle } from "./helpers";
import { enableConsoleLog } from "./logger";

export function exposeConsoleLogMethod() {
  window.viewportLog = (param: string | boolean, logLevel: string): void => {
    if (typeof param === "boolean") {
      setDebugEnabled(param);
      return;
    }

    const normalizedLevel = param.toUpperCase();
    const debugLevelSet = DEBUG_LEVEL[normalizedLevel as keyof typeof DEBUG_LEVEL]
    const normalizedLogLevel = logLevel && logLevel?.toUpperCase() || LOG_LEVEL.ALL;
    const logLevelSet = LOG_LEVEL[normalizedLogLevel as keyof typeof LOG_LEVEL]
    setLogLevel(logLevelSet || LOG_LEVEL.ALL)

    if (debugLevelSet) {
      setDebugEnabled(true);
      enableConsoleLog(normalizedLevel, normalizedLogLevel);
    } else {
      console.warn("%c Invalid debug level specified", getStyle("error"));
      setDebugEnabled(false);
    }
  };
}

export function isMethodEnabled(logLevel: string) {
  return getLogLevel() === logLevel || getLogLevel() === LOG_LEVEL.ALL;
}
