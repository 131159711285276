import { v4 as uuidv4 } from "uuid";

export function getDateString(date: Date) {
  // account for potential timezone difference
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const options = {
    day: "numeric" as "numeric" | "2-digit",
    month: "long" as "long" | "numeric" | "2-digit" | "short" | "narrow",
  };

  return date.toLocaleDateString("en-US", options);
}

export function getFormattedDateString(date: Date) {
  // account for potential timezone difference
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const options = {
    day: "numeric" as "numeric" | "2-digit",
    month: "long" as "long" | "numeric" | "2-digit" | "short" | "narrow",
  };

  return date.toLocaleDateString("en-US", options);
}

export function isAllowedEmail(email = "") {
  let hasError = false;
  if (!email.length) {
    hasError = true;
  }

  if (email.indexOf("@") === -1) {
    hasError = true;
  }

  const emailParts = email.split("@");
  if (
    emailParts.length !== 2 ||
    !emailParts[0].length ||
    !emailParts[1].length
  ) {
    hasError = true;
  }

  const domain = emailParts[1];
  if (!domain?.length) {
    hasError = true;
  }
  if (domain?.indexOf(".") === -1) {
    hasError = true;
  }

  const domainParts = domain?.split(".");
  if (domainParts?.some((x) => !x.length)) {
    hasError = true;
  }

  const disallowedTLDs = ["gov", "mil"];
  if (disallowedTLDs.includes(domainParts?.[domainParts?.length - 1])) {
    hasError = true;
  }

  if (!validateEmail(email)) {
    hasError = true;
  }

  if (hasError) {
    return { isValid: false, message: "The email address is invalid." };
  }

  return { isValid: true, message: "Email Allowed" };
}

export function validateEmail(e: string) {
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/i;
  return emailRegex.test(e);
}

export const getIso8601String = (time: string): string => {
  return `PT${time
    .replace(/\bminutes?\b/gi, "M")
    .replace(/\bhours?\b/gi, "H")
    .replace(/\s/g, "")
    .trim()}`;
};

export function getRandomHash(length: number) {
  return [...Array(length)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
}

export function getStatSigClientSdkKey() {
  return process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY ?? "";
}

export function pickKeysFromObject(obj: any, keys: string[]) {
  return Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]]),
  );
}

export function waitForElementWithSelector(selector: string) {
  return new Promise((resolve) => {
    const element = document.querySelector(selector);
    if (element) {
      resolve(element);
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function getImageDimensions(size: string): number[] {
  // returns [width, height]
  switch (size) {
    case "small":
      return [300, 225];
    case "large":
      return [800, 600];
    case "medium":
    default:
      return [600, 450];
  }
}

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    typeof window !== "undefined" ? navigator.userAgent : "",
  );
}

export function handleUserId(): string {
  let id = localStorage.getItem("uuid") || "";
  if (id) {
    return id;
  }
  id = uuidv4();
  localStorage.setItem("uuid", id);
  return id;
}

export function handleSessionId(): string {
  let id = sessionStorage.getItem("session_id") || "";
  if (id) {
    return id;
  }
  id = uuidv4();
  sessionStorage.setItem("session_id", id);
  return id;
}

export  async function getClientIpAddress () {
  const ipResponse = await fetch("/api/get-client-ip-address");
  const data = await ipResponse.json();
  return data?.realIP ?? data?.ipAddress ?? data?.forwardedFor ?? "Unknown";
}
