"use client";

import { useEffect } from "react";

import { usePathname } from "next/navigation";

import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

import { ezoicDestroyAll } from "shared-lib/helpers/ezoicFunctions";
import { logInfo } from "shared-lib/helpers/logging/logger";

import { GlobalStateProvider } from "@/context/globalState";
import { PAGE_NAMES, PAGES_WITH_ADS } from "@/lib/constants";

export default function Providers({
  children,
  isEnabled,
}: {
  children: React.ReactNode;
  isEnabled: boolean;
}) {
  const pathName = usePathname();
  const path = pathName.split("/")[1] as PAGE_NAMES || "home" as PAGE_NAMES;

  useEffect(() => {
    if (!PAGES_WITH_ADS.includes(path)) {
      logInfo("Ezoic: [destroyAll() method]");
      ezoicDestroyAll();
    }
  }, [path]);

  return (
    <GlobalStateProvider>
      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={true}
        enableLiveUpdates={true}
        debugMode={isEnabled}
      >
        {children}
      </ContentfulLivePreviewProvider>
    </GlobalStateProvider>
  );
}
