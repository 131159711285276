import { message, DEBUG_LEVEL, LOG_LEVEL } from "./constants";
import {
  getDebugEnabled,
  getDebugLevel,
  setDebugLevel,
  setLogLevel,
} from "./cookieManager";
import { getStyle, shouldUseDebug } from "./helpers";

export function log(...args: any) {
  if (shouldUseDebug(args, getDebugLevel(), getDebugEnabled(), LOG_LEVEL.LOG)) {
    console.log("%c %s", getStyle("default"), `${message}`, ...args);
  }
}

export function logError(...args: any) {
  if (
    shouldUseDebug(args, getDebugLevel(), getDebugEnabled(), LOG_LEVEL.ERROR)
  ) {
    console.log("%c %s", getStyle("error"), message, ...args);
  }
}

export function logInfo(...args: any) {
  if (
    shouldUseDebug(args, getDebugLevel(), getDebugEnabled(), LOG_LEVEL.INFO)
  ) {
    console.log("%c %s", getStyle("info"), message, ...args);
  }
}

export function enableConsoleLog(level: string, logLevel: string) {
  const normalizedLevel = level.toUpperCase();
  const normalizedLogLevel = logLevel.toUpperCase();
  if (!(normalizedLevel in DEBUG_LEVEL)) {
    console.warn("%c Invalid debug level provided", getStyle("error"));
    return;
  }

  setDebugLevel(normalizedLevel);
  setLogLevel(normalizedLogLevel);
  console.log(
    "%c %s",
    getStyle("default"),
    message,
    `DEBUG level is set to ${DEBUG_LEVEL[normalizedLevel as keyof typeof DEBUG_LEVEL]} - LOG level is set to ${LOG_LEVEL[normalizedLogLevel as keyof typeof LOG_LEVEL]}`,
  );
}
