import {
  useReducer,
  useContext,
  createContext,
  ReactNode,
  Dispatch,
} from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { getReCaptchaSiteKey } from "shared-lib/helpers/envFunctions";

export enum GlobalActionTypes {
  ADD_TIME = "ADD_TIME",
  ATTEMPTED_SUBSCRIPTION = "ATTEMPTED_SUBSCRIPTION",
  SET_MD5 = "SET_MD5",
  SET_CURRENT_RECIPE = "SET_CURRENT_RECIPE",
  SET_MISSING_SUBSCRIBE = "SET_MISSING_SUBSCRIBE",
  UPDATE_EXPERIMENT_VALUE = "UPDATE_EXPERIMENT_VALUE",
  SET_VISITED_PAGE = "SET_VISITED_PAGE",
  RESET_VISITED_PAGE = "RESET_VISITED_PAGE",
  SET_DEFINED_PLACEHOLDERS = "SET_DEFINED_PLACEHOLDERS",
}

type AddTimeAction = {
  type: typeof GlobalActionTypes.ADD_TIME;
};

type AttemptedSubscriptionAction = {
  type: typeof GlobalActionTypes.ATTEMPTED_SUBSCRIPTION;
};

type SetMd5Action = {
  type: typeof GlobalActionTypes.SET_MD5;
  payload: string;
};

type SetCurrentRecipe = {
  type: typeof GlobalActionTypes.SET_CURRENT_RECIPE;
  payload: Recipe | undefined;
};

type SetMissingSubscribe = {
  type: typeof GlobalActionTypes.SET_MISSING_SUBSCRIBE;
  payload: boolean;
};

type UpdateExperimentValue = {
  type: typeof GlobalActionTypes.UPDATE_EXPERIMENT_VALUE;
  payload: {
    key: string;
    value: boolean | string;
  };
};

type SetVisitedPage = {
  type: typeof GlobalActionTypes.SET_VISITED_PAGE;
  payload: string;
};

type ResetVisitedPage = {
  type: typeof GlobalActionTypes.RESET_VISITED_PAGE;
};

type SetDefinedPlaceholders = {
  type: typeof GlobalActionTypes.SET_DEFINED_PLACEHOLDERS;
  payload: number[];
};

type AcceptedActionType =
  | AddTimeAction
  | AttemptedSubscriptionAction
  | SetMd5Action
  | SetCurrentRecipe
  | SetMissingSubscribe
  | UpdateExperimentValue
  | SetDefinedPlaceholders
  | SetVisitedPage
  | ResetVisitedPage;

type State = {
  count: number;
  hasAttemptedSubcription: boolean;
  executeRecaptcha:
    | ((action?: string | undefined) => Promise<string>)
    | undefined;
  md5: string;
  mediaModalExperiment: string;
  currentRecipe: { entry: Recipe | undefined };
  missingSubscribe: boolean;
  seenLandingPage: boolean;
  experiments: { key: string; value: boolean | string }[];
  navigation: {
    visitedPages: string[];
  };
  definedPlaceholders: number[];
};

export const initialState: State = {
  count: 0,
  hasAttemptedSubcription: false,
  executeRecaptcha: undefined,
  md5: "",
  mediaModalExperiment: "",
  currentRecipe: { entry: undefined },
  missingSubscribe: true,
  seenLandingPage: false,
  experiments: [],
  navigation: {
    visitedPages: [],
  },
  definedPlaceholders: [],
};

const GlobalStateContext = createContext<[State, Dispatch<AcceptedActionType>]>(
  [
    initialState,
    () => {
      return;
    },
  ],
);

const reducer = (state: State, action: AcceptedActionType) => {
  const newState = { ...state };
  switch (action.type) {
    case "ATTEMPTED_SUBSCRIPTION":
      newState.hasAttemptedSubcription = true;
      break;
    case "SET_MD5":
      newState.md5 = action.payload;
      break;
    case GlobalActionTypes.SET_CURRENT_RECIPE:
      newState.currentRecipe.entry = action.payload;
      break;
    case GlobalActionTypes.SET_MISSING_SUBSCRIBE:
      newState.missingSubscribe = action.payload;
      break;
    case GlobalActionTypes.UPDATE_EXPERIMENT_VALUE: {
      const objectIndex = newState.experiments.findIndex(
        (item) => item.key === action.payload.key,
      );
      if (objectIndex < 0) {
        newState.experiments.push({
          key: action.payload.key,
          value: action.payload.value,
        });
      } else {
        newState.experiments[objectIndex].value = action.payload.value;
      }
      break;
    }
    case GlobalActionTypes.SET_VISITED_PAGE:
      newState.navigation.visitedPages = Array.from(
        new Set(state.navigation.visitedPages?.concat(action.payload)),
      );
      break;
    case GlobalActionTypes.RESET_VISITED_PAGE:
      newState.navigation.visitedPages = [];
      break;
    case GlobalActionTypes.SET_DEFINED_PLACEHOLDERS:
      newState.definedPlaceholders = Array.from(
        new Set(action.payload.concat(state.definedPlaceholders)),
      );
      break;
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
  return newState;
};

export const GlobalStateProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaSiteKey()}>
      <GlobalStateContext.Provider value={[state, dispatch]}>
        {children}
      </GlobalStateContext.Provider>
    </GoogleReCaptchaProvider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
