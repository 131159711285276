import { TRACKING_LEVEL } from "./constants";
import { isMethodEnabled } from "./exposeLogMethod";

export function getStyle(level: "default" | "error" | "info"): string {
  const baseStyle = "color: #FFF; padding: 2px; border-radius: 5px; line-height: 16px;";
  switch (level) {
    case "error": return `background: #B53F51; ${baseStyle}`;
    case "info": return `background: #3A8EDB; ${baseStyle}`;
    default: return `background: #3F51B5; ${baseStyle}`;
  }
}

export function shouldUseDebug(args: any[], currentDebugLevel: string, debugEnabled: boolean, logLevel: string): boolean {
  if (!debugEnabled) { return false; }
  if (!isMethodEnabled(logLevel)) { return false; }
  if (currentDebugLevel === "ALL") { return true; }

  const destination = args[2]?.destination?.toLowerCase() || "";
  return (
    (currentDebugLevel === "GA" && destination === "ga") ||
    (currentDebugLevel === "SS" && destination === "ss") ||
    (currentDebugLevel === "JT" && destination === "jt") ||
    (currentDebugLevel === "TRACKING" && TRACKING_LEVEL.includes(destination))
  );
}
