export async function handleResponseError(res: Response) {
  let errMsg = `Invalid response status: ${res.status}`;

  if(res.statusText) {
    errMsg += `, Status Text: ${res.statusText}`;
  }
  const headers = res.headers;
  if (headers) {
    errMsg += `, Response Headers: ${JSON.stringify(headers)}`;
  }

  if(res.body) {
    try {
      const body = await res.json();
      errMsg += `, Response Body: ${JSON.stringify(body)}`;
    } catch {
      errMsg += `, Unable to parse body into JSON`;
    }
  }

  throw new Error(errMsg);
}
