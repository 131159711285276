"use client";
import { useEffect } from "react";

import { usePathname } from "next/navigation";
import Script from "next/script";

import { GA_TRACKING_ID } from "shared-lib/gtag";
import { getContentPath } from "shared-lib/helpers/frontendFunctions";

export default function GATracker() {
  const pathName = usePathname();

  useEffect(() => {
    const contentGroup = getContentPath(pathName ?? "");
    window.gtag("set", "content_group", contentGroup);
  }, [pathName]);
  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script id="gtag-init">
        {`
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
          });
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
    </>
  );
}
