"use client";

import { Fragment, useEffect } from "react";

import { USE_EZOIC } from "shared-lib/constants";
import { useCustomGate } from "shared-lib/helpers/customStatSigHooks";
import {
  ezoicCallForAds,
  ezoicDefinePlaceholders,
  ezoicDisplayAds,
  ezoicRefreshAds,
} from "shared-lib/helpers/ezoicFunctions";
import { logInfo } from "shared-lib/helpers/logging/logger";
import {
  isMobile,
  waitForElementWithSelector,
} from "shared-lib/helpers/utilityFunctions";

import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import { PAGE_NAMES } from "@/lib/constants";

const ID_TO_BE_REMOVED = 116;

type Props = {
  placeholders: number[];
  mobileOnly: number[];
  desktopOnly: number[];
};

export default function EzoicHandler({
  placeholders,
  mobileOnly,
  desktopOnly,
}: Props) {
  const isEzoicVidePlayer = useCustomGate("ezoic_video_player");
  const [globalState, dispatch] = useGlobalState();

  useEffect(() => {
    if (USE_EZOIC) {
      logInfo("Ezoic: [use Ezoic]");
      const listOfPlaceholders = placeholders;
      if (isMobile()) {
        listOfPlaceholders.push(...mobileOnly);
      } else {
        listOfPlaceholders.push(...desktopOnly);
      }

      dispatch({
        type: GlobalActionTypes.SET_DEFINED_PLACEHOLDERS,
        payload: Array.from(new Set(listOfPlaceholders)),
      });

      if (isEzoicVidePlayer) {
        const indexOfId = listOfPlaceholders.indexOf(ID_TO_BE_REMOVED);
        if (indexOfId > -1) {
          listOfPlaceholders.splice(indexOfId, 1);
        }
      }

      const placeholdersInTheDom = listOfPlaceholders.map((id: number) =>
        waitForElementWithSelector("#ezoic-pub-ad-placeholder-" + id),
      );

      if (
        globalState.navigation.visitedPages.includes(PAGE_NAMES.ARTICLE) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.TAG) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.RECIPE) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.CATEGORY) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.HOME)
      ) {
        const placeholdersToBeDefined = listOfPlaceholders.filter(
          (placeholderId) =>
            !globalState.definedPlaceholders.includes(placeholderId),
        );

        ezoicDefinePlaceholders(
          Array.from(
            new Set(placeholdersToBeDefined.concat(listOfPlaceholders)),
          ).sort(),
        );

        setTimeout(() => {
          logInfo("Ezoic: [refresh()]");
          ezoicRefreshAds();
        }, 250);
      } else {
        Promise.race(placeholdersInTheDom).then(() => {
          logInfo("Ezoic: [define() / enable()]");
          ezoicDefinePlaceholders(listOfPlaceholders.sort());
          ezoicCallForAds();
          ezoicDisplayAds();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment key="ezoic-handler" />;
}
