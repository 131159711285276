import {
  getQueryParams,
  ParamsObject,
} from "shared-lib/helpers/storageFunctions";

type ALLOWED_REQUIRED_FIELDS = "firstName" | "lastName";

export type SubscriptionRequestBody = {
  swp?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  token: string | undefined;
  localStorageValues: any;
  ip: string;
  requiredFields?: { [K in ALLOWED_REQUIRED_FIELDS]: boolean } | undefined;
  userId?: string;
  sessionId?: string;
};

export type FullSubscriptionBody = SubscriptionRequestBody & {
  userAgent: string;
  queryParams: ParamsObject;
};

export async function sendSubscribeRequest(body: SubscriptionRequestBody) {
  const fullBody: FullSubscriptionBody = {
    ...body,
    userAgent: navigator.userAgent,
    queryParams: getQueryParams(),
  };

  return await fetch("/api/subscribe", {
    method: "POST",
    body: JSON.stringify(fullBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
