import { getCookie, setCookie } from "cookies-next";

import { DEBUG_ENABLED_NAME, DEBUG_LEVEL_COOKIE, LOG_LEVEL_COOKIE } from "./constants";

export const getDebugEnabled = () => getCookie(DEBUG_ENABLED_NAME) === "true";
export const setDebugEnabled = (enabled: boolean) => setCookie(DEBUG_ENABLED_NAME, enabled.toString());

export const getDebugLevel = () => getCookie(DEBUG_LEVEL_COOKIE) || "";
export const setDebugLevel = (level: string) => setCookie(DEBUG_LEVEL_COOKIE, level);

export const getLogLevel = () => getCookie(LOG_LEVEL_COOKIE) || "";
export const setLogLevel = (level: string) => setCookie(LOG_LEVEL_COOKIE, level);
