import Brands from "./Brands";

export type BrandInfo = {
  vertical: "info" | "jokes" | "recipes" | "words";
  id: Brands;
  name: string;
  description: string;
  facebook?: string;
  instagram?: string;
  pinterest?: string;
  tiktok?: string;
  twitter?: string;
  youtube?: string;
  adPrefix: string;
  contentPrefix: string;
  baseUrl: string;
  assetPrefix: string;
  seo?: SEO;
};

type SEO = {
  title: string;
  description: string;
  keywords: string;
};

type BrandSet = {
  [name in Brands]: BrandInfo;
};

export const BrandData: BrandSet = {
  bigrecipe: {
    id: Brands.bigrecipe,
    vertical: "recipes",
    name: "Big Recipe",
    adPrefix: "BR",
    assetPrefix: "/brand/BigRecipe",
    baseUrl: "https://bigrecipe.com",
    contentPrefix: "content/brand/BigRecipe",
    description: "Big Hits for Every Palate!",
    facebook: "https://www.facebook.com/profile.php?id=61562786952426",
    instagram: "https://www.instagram.com/bigrecipe_",
    pinterest: "https://www.pinterest.com/BigRecipedotcom/",
    tiktok: "https://www.tiktok.com/@bigrecipedotcom",
  },
  boldfact: {
    id: Brands.boldfact,
    vertical: "info",
    name: "Bold Fact",
    adPrefix: "BF",
    assetPrefix: "/content/BoldFact",
    baseUrl: "https://boldfact.com",
    contentPrefix: "",
    description: "Info to you boldly all the time!",
    facebook: "https://www.facebook.com/BoldFactdotcom/",
    instagram: "https://www.instagram.com/boldfactdotcom",
    pinterest: "https://www.pinterest.com/BoldFactdotcom/",
    tiktok: "https://www.tiktok.com/@boldfactdotcom",
    twitter: "https://x.com/boldfactdotcom",
    youtube: "https://www.youtube.com/@BoldFactdotcom",
    seo: {
      title: "Bold Fact",
      description: "Info to you boldly all the time!",
      keywords: "Fascinating Facts, Crime & Punishment, Happy Happenings",
    },
  },
  commandcooking: {
    id: Brands.commandcooking,
    vertical: "recipes",
    name: "Command Cooking",
    adPrefix: "CC",
    assetPrefix: "/brand/CommandCooking",
    baseUrl: "https://commandcooking.com",
    contentPrefix: "content/brand/CommandCooking",
    description: "Control the Kitchen",
    facebook: "https://facebook.com/CommandCooking",
    instagram: "https://www.instagram.com/commandcooking",
    pinterest: "https://pinterest.com/commandcooking",
    tiktok: "https://www.tiktok.com/@commandcooking",
  },
  cookingprofessionally: {
    id: Brands.cookingprofessionally,
    vertical: "recipes",
    name: "Cooking Professionally",
    adPrefix: "CP",
    assetPrefix: "/brand/CookingProfessionally",
    baseUrl: "https://cookingprofessionally.com",
    contentPrefix: "content/brand/CookingProfessionally",
    description: "Cook Your Heart Out!",
    facebook: "https://facebook.com/cookingprofessionally",
    instagram: "https://www.instagram.com/cooking.professionally",
    pinterest: "https://pinterest.com/cookingprofessionally",
    tiktok: "https://www.tiktok.com/@cookingprofessionally",
  },
  dictionarybee: {
    id: Brands.dictionarybee,
    vertical: "words",
    name: "Dictionary Bee",
    adPrefix: "DB",
    assetPrefix: "/brand/DictionaryBee",
    baseUrl: "https://dictionarybee.com",
    contentPrefix: "content/brand/DictionaryBee",
    description: "",
    facebook: "",
    twitter: "",
  },
  easiereats: {
    id: Brands.easiereats,
    vertical: "recipes",
    name: "Easier Eats",
    adPrefix: "EE",
    assetPrefix: "/brand/EasierEats",
    baseUrl: "https://easiereats.com",
    contentPrefix: "content/brand/EasierEats",
    description: "Easy Eating",
    facebook: "https://facebook.com/easiereats",
    pinterest: "https://pinterest.com/easiereats",
  },
  floridasfunny: {
    id: Brands.floridasfunny,
    vertical: "jokes",
    name: "Florida's Funny",
    adPrefix: "FF",
    assetPrefix: "/brand/FloridasFunny",
    baseUrl: "https://floridasfunny.com",
    contentPrefix: "content/brand/FloridasFunny",
    description: "",
    facebook: "",
    twitter: "",
  },
  funnyeditor: {
    id: Brands.funnyeditor,
    vertical: "jokes",
    name: "Funny Editor",
    adPrefix: "FE",
    assetPrefix: "/brand/FunnyEditor",
    baseUrl: "https://funnyeditor.com",
    contentPrefix: "content/brand/FunnyEditor",
    description: "Jokes for days!",
    facebook: "https://facebook.com/funnyeditordotcom",
    twitter: "https://twitter.com/FunnyEditor_",
  },
  fussykitchen: {
    id: Brands.fussykitchen,
    vertical: "recipes",
    name: "Fussy Kitchen",
    adPrefix: "FK",
    assetPrefix: "/brand/FussyKitchen",
    baseUrl: "https://fussykitchen.com",
    contentPrefix: "content/brand/FussyKitchen",
    description: "",
    facebook: "https://www.facebook.com/FussyKitchendotcom",
    tiktok: "https://www.tiktok.com/@fussy.kitchen",
    instagram: "https://www.instagram.com/fussy.kitchen",
    pinterest: "https://www.pinterest.com/fussykitchen",
  },
  goodcleanhumor: {
    id: Brands.goodcleanhumor,
    vertical: "jokes",
    name: "Good Clean Humor",
    adPrefix: "GCH",
    assetPrefix: "/brand/GoodCleanHumor",
    baseUrl: "https://goodcleanhumor.com",
    contentPrefix: "content/brand/GoodCleanHumor",
    description: "Good, clean jokes to make you laugh!",
    facebook: "https://facebook.com/GoodCleanHumordotcom",
    twitter: "https://twitter.com/GoodCleanHumor_",
  },
  heartlandcooking: {
    id: Brands.heartlandcooking,
    vertical: "recipes",
    name: "Heartland Cooking",
    adPrefix: "HLC",
    assetPrefix: "/brand/HeartlandCooking",
    baseUrl: "https://heartlandcooking.com",
    contentPrefix: "content/brand/HeartlandCooking",
    description: "Straight from the Heartland",
    facebook: "https://facebook.com/HeartlandCookingRecipes",
    pinterest: "https://pinterest.com/heartlandcooking",
    instagram: "https://www.instagram.com/heartland.cooking",
    tiktok: "https://www.tiktok.com/@heartlandcooking",
  },
  holyrecipe: {
    id: Brands.holyrecipe,
    vertical: "recipes",
    name: "Holy Recipe",
    adPrefix: "HR",
    assetPrefix: "/brand/HolyRecipe",
    baseUrl: "https://holyrecipe.com",
    contentPrefix: "content/brand/HolyRecipe",
    description: "",
    facebook: "https://www.facebook.com/HolyRecipedotcom",
    tiktok: "https://www.tiktok.com/@holy.recipe",
    instagram: "https://www.instagram.com/holyrecipedotcom",
    pinterest: "https://www.pinterest.com/holyrecipedotcom",
  },
  lazycooking: {
    id: Brands.lazycooking,
    vertical: "recipes",
    name: "Lazy Cooking",
    adPrefix: "LC",
    assetPrefix: "/brand/LazyCooking",
    baseUrl: "https://lazycooking.com",
    contentPrefix: "content/brand/LazyCooking",
    description: "Easy as Pie",
    facebook: "https://facebook.com/LazyCookingRecipes",
    pinterest: "https://pinterest.com/lazycookingrecipes",
  },
  mamascooking: {
    id: Brands.mamascooking,
    vertical: "recipes",
    name: "Mama's Cooking",
    adPrefix: "MC",
    assetPrefix: "/brand/MamasCooking",
    baseUrl: "https://mamascooking.com",
    contentPrefix: "content/brand/MamasCooking",
    description: "The Taste of Home",
    facebook: "https://facebook.com/MamasCookingRecipe",
    pinterest: "https://pinterest.com/mamascookingrecipes",
  },
  masterofwit: {
    id: Brands.masterofwit,
    vertical: "jokes",
    name: "Master of Wit",
    adPrefix: "MW",
    assetPrefix: "/brand/MasterOfWit",
    baseUrl: "https://masterofwit.com",
    contentPrefix: "content/brand/MasterOfWit",
    description: "",
    facebook: "",
    twitter: "",
  },
  mydailyjoke: {
    id: Brands.mydailyjoke,
    vertical: "jokes",
    name: "My Daily Joke",
    adPrefix: "MDJ",
    assetPrefix: "/brand/MyDailyJoke",
    baseUrl: "https://mydailyjoke.com",
    contentPrefix: "content/brand/MyDailyJoke",
    description: "",
    facebook: "https://www.facebook.com/MyDailyJoke",
    instagram: "https://www.instagram.com/mydailyjokedotcom",
    twitter: "https://x.com/MyDailyJoke_",
  },
  recipereader: {
    id: Brands.recipereader,
    vertical: "recipes",
    name: "Recipe Reader",
    adPrefix: "RR",
    assetPrefix: "/brand/RecipeReader",
    baseUrl: "https://recipereader.com",
    contentPrefix: "content/brand/RecipeReader",
    description: "There're recipes to be read.",
    facebook: "https://facebook.com/RecipeReaders",
    instagram: "https://www.instagram.com/recipereader_",
    pinterest: "https://pinterest.com/recipereaderrecipes",
    tiktok: "https://www.tiktok.com/@recipe.reader",
  },
  recipesaver: {
    id: Brands.recipesaver,
    vertical: "recipes",
    name: "Recipe Saver",
    adPrefix: "RS",
    assetPrefix: "/brand/RecipeSaver",
    baseUrl: "https://recipesaver.com",
    contentPrefix: "content/brand/RecipeSaver",
    description: "Flavors for Savors",
    facebook: "https://facebook.com/RecipeSavers",
    instagram: "https://www.instagram.com/recipe.saver",
    pinterest: "https://pinterest.com/recipesavers",
    tiktok: "https://www.tiktok.com/@recipe.saver",
  },
  recipesdirect: {
    id: Brands.recipesdirect,
    vertical: "recipes",
    name: "Recipes Direct",
    adPrefix: "RD",
    assetPrefix: "/brand/RecipesDirect",
    baseUrl: "https://recipesdirect.com",
    contentPrefix: "content/brand/RecipesDirect",
    description: "Just the Recipe",
    facebook: "https://facebook.com/RecipesDirect",
    pinterest: "https://pinterest.com/recipesdirectrecipes",
  },
  shesofunny: {
    id: Brands.shesofunny,
    vertical: "jokes",
    name: "She's So Funny",
    adPrefix: "SSF",
    assetPrefix: "/brand/SheSoFunny",
    baseUrl: "https://shesofunny.com",
    contentPrefix: "content/brand/SheSoFunny",
    description: "",
    facebook: "https://www.facebook.com/SheSoFunnydotcom",
    instagram: "https://www.instagram.com/shesofunnydotcom",
    twitter: "https://x.com/SheSoFunny_",
  },
  thisread: {
    id: Brands.thisread,
    vertical: "info",
    name: "This Read",
    adPrefix: "This Read",
    assetPrefix: "/content/ThisRead",
    baseUrl: "https://thisread.com",
    contentPrefix: "",
    description: "",
    facebook: "",
    instagram: "",
    pinterest: "",
    tiktok: "",
    twitter: "",
    seo: {
      title: "This Read",
      description: "",
      keywords: "",
    },
  },
  wordlancers: {
    id: Brands.wordlancers,
    vertical: "words",
    name: "Word Lancers",
    adPrefix: "WL",
    assetPrefix: "/brand/WordLancers",
    baseUrl: "https://wordlancers.com",
    contentPrefix: "content/brand/WordLancers",
    description: "",
    facebook: "https://www.facebook.com/WordLancers",
    twitter: "https://x.com/WordLancers",
    instagram: "https://www.instagram.com/wordlancers",
  },
  wordzapp: {
    id: Brands.wordzapp,
    vertical: "words",
    name: "Word Zapp",
    adPrefix: "WZ",
    assetPrefix: "/brand/WordZapp",
    baseUrl: "https://wordzapp.com",
    contentPrefix: "content/brand/WordZapp",
    description: "",
    facebook: "",
    twitter: "",
  },
  truthfully: {
    id: Brands.truthfully,
    vertical: "info",
    name: "Truthfully",
    adPrefix: "TF",
    assetPrefix: "/content/Truthfully",
    baseUrl: "https://truthfully.com",
    contentPrefix: "",
    description: "Info to you truthfully all the time!",
    facebook: "https://facebook.com/truthfullydotcom",
    instagram: "https://www.instagram.com/truthfullydotcom",
    pinterest: "https://pinterest.com/truthfullydotcom",
    tiktok: "https://www.tiktok.com/@truthfullydotcom",
    twitter: "https://twitter.com/readtruthfully",
    seo: {
      title: "Truthfully",
      description: "Info to you truthfully all the time!",
      keywords: "Fascinating Facts, Crime & Punishment, Happy Happenings",
    },
  },
};
export default BrandData;
